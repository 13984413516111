.cde-sidebar {
  margin: 20px 20px 0px 20px;
}

.cde-post-title {
  text-shadow: 2px 2px #aeb2c3;
}

@media (min-width: 48em) {
  .cde-sidebar {
    margin: 20px;
  }
}

@media (min-width: 48em) {
  .cde-sidebar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 18rem;
    text-align: left;
  }
}

.cde-site-title a {
  font-family: 'Courier Prime', monospace;
  color: #b24d7a !important;
}

.cde-sidebar-window {
}

.cde-content-area {
  padding: 0 5px;
}

@media (min-width: 48em) {
  .cde-content-area {
    margin-left: calc(18rem + 20px);
  }
}
