.cde-body {
  image-rendering: auto;
}

.cde-body a {
  color: $link-base-color;
  text-decoration: none;

  &:visited {
    color: $link-visited-color;
  }

  &:hover {
    color: $link-hover-color;
    text-decoration: none;
    outline: 1px solid #b24d7a;
    cursor: cursor;
  }

  // .social-media-list &:hover {
    // text-decoration: none;

    // .username {
      // text-decoration: underline;
    // }
  // }
}

body {
  background: #393451;
}

.cde-gradient {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-repeat: repeat-x;
  background-position: center;
  background-image: url(/ps/gradient.png);
  background-blend-mode: screen;
  background-size: 32px 100vh;
  z-index: -99999;
}

.post-list {
  margin-left: 0;
  list-style: none;
}

// Each Post
.cde-window {
  background: #dfe0e3;
  image-rendering: pixelated;
  border-image: url(/ps/border.png) repeat;
  border-image-slice: 25 25 29 25;
  border-width: 25px 25px 29px 25px;
  border-style: solid;
}

li:not(:hover) .cde-window {
  border-image: url(/ps/border-gray.png) repeat;
  border-image-slice: 25 25 29 25;
}

.cde-pane {
  padding: 15px;
  background: #dfe0e3;
  border-width: 1px;
  border-style: outset;
}

.cde-window .cde-titlebar {
  margin: -20px -20px 20px -20px;
}

.cde-titlebar {
  display: flex;
  height: 19px;
  image-rendering: pixelated;
}

.cde-titlebar-left {
  display: flex;
}

.cde-titlebar-center {
  width: 100%;
  border-width: 1px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;

  border-color: #dcadc2 #57253b #57253b #dcadc2;
  background: #b24d7a;

  .cde-titlebar-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 70vw;
    font-family: 'Courier Prime', monospace;
    color: white;
    font-size: 13px;
    text-align: center;
    line-height: normal;
  }
}

// titlebar gray
// li:not(:first-child) .cde-titlebar-center {
li:not(:hover) .cde-titlebar-center {
  border-color: #dcdee5 #5d6069 #5d6069 #dcdee5;
  background: #aeb2c3;

  .cde-titlebar-text {
    color: black;
  }
}

.cde-titlebar-right {
  display: flex;
}

.cde-titlebar-icon {
  width: 19px;
  height: 19px;
  background-image: url(/ps/buttons.png)
}

.cde-titlebar-icon:hover {
  background-blend-mode: hard-light;
  background-color: rgba(255, 255, 255, 0.3);
}

// li:not(:first-child) .cde-titlebar-icon {
li:not(:hover) .cde-titlebar-icon {
  background-image: url(/ps/buttons-gray.png)
}

.cde-menu {
  background-position: -57px 0px;
  cursor: not-allowed;
}

.cde-menu:active {
  background-position: 0px 0px;
}

.cde-maximize {
  background-position: -76px 0px;
  cursor: zoom-in;
}

.cde-maximize:active {
  background-position: -19px 0px;
}

.cde-minimize {
  background-position: -95px 0px;
  cursor: zoom-out;
}

.cde-minimize:active {
  background-position: -38px 0px;
}

