@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&family=Roboto+Mono&family=Roboto&display=swap');

@font-face {
  font-family: 'AndaleMono';
  font-weight: 400;
  src: url('andalemo.ttf') format('truetype');
}

// Define defaults for each variable.
$base-font-family: 'Roboto', -apple-system, BlinkMacSystemFont, Helvetica, Arial, "Segoe UI", "Segoe UI Symbol", "Segoe UI Emoji", "Apple Color Emoji", sans-serif !default;
$code-font-family: "Menlo", "Inconsolata", "Consolas", "Roboto Mono", "Ubuntu Mono", "Liberation Mono", "Courier New", monospace;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;
$spacing-unit:     20px !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    1000px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

$on-medium:        $on-palm !default;
$on-large:         $on-laptop !default;

// Notice the following mixin uses max-width, in a deprecated, desktop-first
// approach, whereas media queries used elsewhere now use min-width.
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: #{$ratio}rem;
}

// Import pre-styling-overrides hook and style-partials.
@import
  "cde-blog/base",             // Defines element resets.
  "cde-blog/layout",          // Defines structure and style based on CSS selectors.
  "cde-blog/cde"              // Defines CDE style.
;
